<template>
  <v-container class="lighten-5 grey container--fluid" style="height: 100%">
    <v-container>
      <v-row>
        <v-col v-for="(item, i) in stats" :key="i" md="4" sm="12" :class="item.class">
          <v-card class="mx-auto text-center pa-6 pb-12 pt-12 elevation-2" :style="item.cardStyle">

            <v-icon
              :color="item.color"
              :style="item.backgroundColor"
              class="rounded-circle pa-4 mb-10"
              x-large
              right
            >
              {{ item.icon }}
            </v-icon>
            <p class="display-3 font-weight-bold darken-4 black--text mb-0">{{ item.value }}{{ item.preset || '' }}</p>
            <v-card-text class=" mt-0 pb-2 green--text font-weight-bold">
              &#9650; {{ getRandomInt(0, 15) }}.{{ getRandomInt(0, 10) }}%
            </v-card-text>
            <v-card-text class=" mt-0 pt-0 font-weight-bold grey--text">
              {{ item.title.charAt(0).toUpperCase() + item.title.slice(1)}}
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
      <v-container class="mt-8 mb-8 text-center">
        <v-btn
          dark

          color="blue"
          class="mr-4 pl-6 pr-6 pt-4 pb-4"
          @click="goToLink('vote')"
        >
          Stem!
        </v-btn>
      </v-container>
      <!--      <v-container>-->
      <!--        <v-card-->
      <!--          class="mx-auto text-center"-->

      <!--        >-->
      <!--          <v-card-text>-->
      <!--            <div class="text-h4 font-weight-thin">-->
      <!--              Tacokasse-->
      <!--            </div>-->
      <!--          </v-card-text>-->
      <!--          <v-card-text>-->
      <!--            <apexchart type="area" height="350" :options="chartOptions" :series="chartSeries"></apexchart>-->

      <!--            &lt;!&ndash;                <v-sheet color="rgba(0, 0, 0, .12)">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <v-sparkline&ndash;&gt;-->
      <!--            &lt;!&ndash;                    :value="value"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    color="rgba(255, 255, 255, .7)"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    height="100"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    padding="24"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    stroke-linecap="round"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    smooth&ndash;&gt;-->
      <!--            &lt;!&ndash;                  >&ndash;&gt;-->
      <!--            &lt;!&ndash;                    <template v-slot:label="item">&ndash;&gt;-->
      <!--            &lt;!&ndash;                      {{ item.value }} kr&ndash;&gt;-->
      <!--            &lt;!&ndash;                    </template>&ndash;&gt;-->
      <!--            &lt;!&ndash;                  </v-sparkline>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </v-sheet>&ndash;&gt;-->
      <!--          </v-card-text>-->

      <!--        </v-card>-->

      <!--      </v-container>-->
      <!--      <v-container>-->
      <!--        <v-row>-->
      <!--          <v-col>-->
      <!--            <v-card-->
      <!--              dark-->
      <!--              class="mx-auto"-->
      <!--            >-->
      <!--              <v-card-text>-->
      <!--                <div class="text-h5 font-weight-thin">-->
      <!--                  Historikk-->
      <!--                </div>-->

      <!--              </v-card-text>-->
      <!--              <v-card-text>-->
      <!--                <v-timeline-->
      <!--                  align-top-->
      <!--                  dense>-->
      <!--                  <v-timeline-item-->
      <!--                    color="amber lighten-1"-->
      <!--                    icon="mdi-taco">-->
      <!--                    <v-row class="pt-1">-->
      <!--                      <v-col cols="5">-->
      <!--                        <strong>01/22 (250)</strong>-->
      <!--                      </v-col>-->
      <!--                      <v-col>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-timeline-item>-->
      <!--                  <v-timeline-item-->
      <!--                    color="amber lighten-1"-->
      <!--                    icon="mdi-taco"-->
      <!--                  >-->
      <!--                    <v-row class="pt-1">-->
      <!--                      <v-col cols="5">-->
      <!--                        <strong>12/21 (250)</strong>-->
      <!--                      </v-col>-->
      <!--                      <v-col>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-timeline-item>-->
      <!--                  <v-timeline-item-->
      <!--                    color="amber lighten-1"-->
      <!--                    icon="mdi-taco"-->
      <!--                  >-->
      <!--                    <v-row class="pt-1">-->
      <!--                      <v-col cols="5">-->
      <!--                        <strong>11/21 (300)</strong>-->
      <!--                      </v-col>-->
      <!--                      <v-col>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-timeline-item>-->

      <!--                  <v-timeline-item-->
      <!--                    color="amber lighten-3"-->
      <!--                    icon="mdi-taco"-->
      <!--                  >-->
      <!--                    <v-row class="pt-1">-->
      <!--                      <v-col cols="5">-->
      <!--                        <strong>10/21 (200)</strong>-->
      <!--                      </v-col>-->
      <!--                      <v-col>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-timeline-item>-->

      <!--                </v-timeline>-->
      <!--              </v-card-text>-->
      <!--            </v-card>-->

      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-container>-->
    </v-container>
  </v-container>
</template>

<script>


import router from '@/router/router';

export default {
  name: 'FrontPage',

  created() {
    this.chartSeries =
      [{
        name: 'Oppmøte',
        data: [
          200,
          300,
          250,
          250,
          600,
          150,
          750,
          150,
          150,
          150
        ],
      }, {
        name: 'Outfit',
        data: [
          50,
          100,
          0,
          200,
          200,
          100,
          0,
          0,
          0,
          0
        ],
      }];
    this.chartOptions.xaxis.categories = ['10/21', '11/21', '12/21', '01/22', '02/22', '03/22', '04/22', '05/22'];
  },
  data: () => ({
    chartSeries: undefined,
    fines: [
      {date: '10/21', outfit: 200, meeting: 0},
      {date: '11/21', outfit: 100, meeting: 100},
      {date: '12/21', outfit: 100, meeting: 150},
      {date: '01/22', outfit: 100, meeting: 150},
      {date: '02/22', outfit: 300, meeting: 300},
      {date: '03/22', outfit: 0, meeting: 150},
      {date: '04/22', outfit: 0, meeting: 150},
      {date: '05/22', outfit: 0, meeting: 150},
      {date: '06/22', outfit: 0, meeting: 300},
    ],
    chartOptions: {
      tooltip: {
        theme: 'dark'
      },
      chart: {
        height: 328,
        type: 'line',
        zoom: {
          enabled: false
        },
        dropShadow: {
          enabled: true,
          top: 3,
          left: 2,
          blur: 4,
          opacity: 1,
        }
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      //colors: ["#3F51B5", '#2196F3'],
      series: [{
        name: 'Music',
        data: [1, 15, 26, 20, 33, 27]
      },
        {
          name: 'Photos',
          data: [3, 33, 21, 42, 19, 32]
        },
        {
          name: 'Files',
          data: [0, 39, 52, 11, 29, 43]
        }
      ],
      title: {
        text: 'Media',
        align: 'left',
        offsetY: 25,
        offsetX: 20
      },
      subtitle: {
        text: 'Statistics',
        offsetY: 55,
        offsetX: 20
      },
      markers: {
        size: 6,
        strokeWidth: 0,
        hover: {
          size: 9
        }
      },
      grid: {
        show: true,
        padding: {
          bottom: 0
        }
      },
      labels: ['01/15/2002', '01/16/2002', '01/17/2002', '01/18/2002', '01/19/2002', '01/20/2002'],
      xaxis: {
        tooltip: {
          enabled: false
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetY: -20
      }
    },
    // chartOptions: {
    //   chart: {
    //     type: 'area',
    //     stacked: false,
    //     height: 350,
    //     zoom: {
    //       enabled: false
    //     },
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   colors: ['#29B6F6', '#26A69A', '#66BB6A', '#FFF176'],
    //   plotOptions: {
    //     bar: {
    //       columnWidth: '45%',
    //       distributed: true,
    //     }
    //   },
    //   fill: {
    //     type: 'gradient',
    //     gradient: {
    //       shadeIntensity: 1,
    //       inverseColors: false,
    //       opacityFrom: 0.45,
    //       opacityTo: 0.05,
    //       stops: [20, 100, 100, 100]
    //     },
    //   },
    //   legend: {
    //     show: false
    //   },
    //   xaxis: {
    //     categories: [],
    //   },
    //   grid: {
    //     show: false
    //
    //   },
    // },

    loadValue: 12,
    value: [
      200,
      300,
      250,
      250,
      600
    ],
    stats: [
      {
        title: 'Gjennomsnittlig score på vertskap',
        value: 5.1,
        icon: 'mdi-home',
        color: 'rgb(0, 204, 0)',
        backgroundColor: 'background-color: rgba(0, 204, 0, 0.1)',
        class: ' mt-2',
        cardStyle: 'border-radius: 4%; margin-top: 12px'

      },
      {
        title: 'Gjennomsnittlig score på taco',
        value: 4.8,
        icon: 'mdi-taco',
        color: 'rgb(0, 51, 204)',
        backgroundColor: 'background-color: rgba(0, 51, 204, 0.1)',
        class: '',
        cardStyle: 'border-radius: 4%; height:410px; z-index:1'
      },
      {
        title: 'Gjennomsnittlig score på narrestreker',
        value: 4.4,
        icon: 'mdi-drama-masks',
        color: 'rgb(204, 51, 255)',
        backgroundColor: 'background-color: rgba(204, 51, 255, 0.1)',
        class: ' mt-2',
        cardStyle: 'border-radius: 4%; margin-top: 12px; z-index:0'

      },
    ]
  }),
  computed: {},
  methods: {
    goToLink(type) {


      window.scrollTo(0, 0);
      router.push(type);
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },

    fineLabels() {
      return this.$data.fines;
    },
  }
};

</script>

<style scoped>

.body-bg {
  background: #f1f2f5;
  color: #777;
  font-family: Montserrat, Arial, sans-serif;
}

</style>
