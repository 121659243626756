<template>
  <v-container class="lighten-5 grey container--fluid" style="height: 100%">
    <v-container>
      <v-row>
        <v-col v-for="(item, i) in stats" :key="i" md="4" sm="12" :class="item.class">
          <v-card class="mx-auto text-center pa-6 pb-12 pt-12 elevation-2" :style="item.cardStyle">

            <v-icon
              :color="item.color"
              :style="item.backgroundColor"
              class="rounded-circle pa-4 mb-10"
              x-large
              right
            >
              {{ item.icon }}
            </v-icon>
            <p class="display-3 font-weight-bold darken-4 black--text mb-0">{{ item.value }}{{ item.preset || '' }}</p>
            <!--            <v-card-text class=" mt-0 pb-2 green&#45;&#45;text font-weight-bold">-->
            <!--              &#9650; {{ getRandomInt(0, 15) }}.{{ getRandomInt(0, 10) }}%-->
            <!--            </v-card-text>-->
            <v-card-text class=" mt-0 pt-6 font-weight-bold grey--text">
              {{ item.title.charAt(0).toUpperCase() + item.title.slice(1) }}
            </v-card-text>
            <v-card-text class=" mt-0 pt-6 grey--text">
              {{ item.total }}
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
      <!--      <v-container>-->
      <!--        <v-card-->
      <!--          class="mx-auto text-center"-->

      <!--        >-->
      <!--          <v-card-text>-->
      <!--            <div class="text-h4 font-weight-thin">-->
      <!--              Tacokasse-->
      <!--            </div>-->
      <!--          </v-card-text>-->
      <!--          <v-card-text>-->
      <!--            <apexchart type="area" height="350" :options="chartOptions" :series="chartSeries"></apexchart>-->

      <!--            &lt;!&ndash;                <v-sheet color="rgba(0, 0, 0, .12)">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <v-sparkline&ndash;&gt;-->
      <!--            &lt;!&ndash;                    :value="value"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    color="rgba(255, 255, 255, .7)"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    height="100"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    padding="24"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    stroke-linecap="round"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    smooth&ndash;&gt;-->
      <!--            &lt;!&ndash;                  >&ndash;&gt;-->
      <!--            &lt;!&ndash;                    <template v-slot:label="item">&ndash;&gt;-->
      <!--            &lt;!&ndash;                      {{ item.value }} kr&ndash;&gt;-->
      <!--            &lt;!&ndash;                    </template>&ndash;&gt;-->
      <!--            &lt;!&ndash;                  </v-sparkline>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </v-sheet>&ndash;&gt;-->
      <!--          </v-card-text>-->

      <!--        </v-card>-->

      <!--      </v-container>-->
      <!--      <v-container>-->
      <!--        <v-row>-->
      <!--          <v-col>-->
      <!--            <v-card-->
      <!--              dark-->
      <!--              class="mx-auto"-->
      <!--            >-->
      <!--              <v-card-text>-->
      <!--                <div class="text-h5 font-weight-thin">-->
      <!--                  Historikk-->
      <!--                </div>-->

      <!--              </v-card-text>-->
      <!--              <v-card-text>-->
      <!--                <v-timeline-->
      <!--                  align-top-->
      <!--                  dense>-->
      <!--                  <v-timeline-item-->
      <!--                    color="amber lighten-1"-->
      <!--                    icon="mdi-taco">-->
      <!--                    <v-row class="pt-1">-->
      <!--                      <v-col cols="5">-->
      <!--                        <strong>01/22 (250)</strong>-->
      <!--                      </v-col>-->
      <!--                      <v-col>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-timeline-item>-->
      <!--                  <v-timeline-item-->
      <!--                    color="amber lighten-1"-->
      <!--                    icon="mdi-taco"-->
      <!--                  >-->
      <!--                    <v-row class="pt-1">-->
      <!--                      <v-col cols="5">-->
      <!--                        <strong>12/21 (250)</strong>-->
      <!--                      </v-col>-->
      <!--                      <v-col>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-timeline-item>-->
      <!--                  <v-timeline-item-->
      <!--                    color="amber lighten-1"-->
      <!--                    icon="mdi-taco"-->
      <!--                  >-->
      <!--                    <v-row class="pt-1">-->
      <!--                      <v-col cols="5">-->
      <!--                        <strong>11/21 (300)</strong>-->
      <!--                      </v-col>-->
      <!--                      <v-col>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-timeline-item>-->

      <!--                  <v-timeline-item-->
      <!--                    color="amber lighten-3"-->
      <!--                    icon="mdi-taco"-->
      <!--                  >-->
      <!--                    <v-row class="pt-1">-->
      <!--                      <v-col cols="5">-->
      <!--                        <strong>10/21 (200)</strong>-->
      <!--                      </v-col>-->
      <!--                      <v-col>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                        <v-avatar>-->
      <!--                          <v-img-->
      <!--                            src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"-->
      <!--                          ></v-img>-->
      <!--                        </v-avatar>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-timeline-item>-->

      <!--                </v-timeline>-->
      <!--              </v-card-text>-->
      <!--            </v-card>-->

      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-container>-->
    </v-container>
  </v-container>
</template>
<script>

import {store} from '../store';

export default {
  name: 'Vote',
  props: {
    msg: String
  },
  mounted() {
    this.getVotes();
  },
  data: () => ({
    e1: 1,
    colors: ['green', 'purple', 'orange', 'indigo', 'red'],
    ratingTaco: 3,
    ratingHost: 3,
    ratingEntertainment: 3,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
    ],
    stats: [
      {
        title: 'Vertskap',
        value: 0,
        icon: 'mdi-home',
        color: 'rgb(0, 204, 0)',
        backgroundColor: 'background-color: rgba(0, 204, 0, 0.1)',
        class: 'pr-0 mt-2',
        cardStyle: 'border-radius: 4% 0 0 4%; margin-top: 12px',
        total: []
      },
      {
        title: 'Taco',
        value: 0,
        icon: 'mdi-taco',
        color: 'rgb(0, 51, 204)',
        backgroundColor: 'background-color: rgba(0, 51, 204, 0.1)',
        class: 'pl-0 pr-0',
        cardStyle: 'border-radius: 4%; height:440px; z-index:1',
        total: []
      },
      {
        title: 'Narrestreker',
        value: 0,
        icon: 'mdi-drama-masks',
        color: 'rgb(204, 51, 255)',
        backgroundColor: 'background-color: rgba(204, 51, 255, 0.1)',
        class: 'pl-0 mt-2',
        cardStyle: 'border-radius: 0 4% 4% 0%; margin-top: 12px; z-index:0',
        total: []

      },
    ],

    voted: false,
    user: 'Velg',
    allHosts: [],
    allEnt: [],
    allTaco: [],
  }),
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    async getVotes() {
      const res = await fetch('https://back-end-service-dot-taco10.nw.r.appspot.com/all-votes', {
        method: 'GET',
      });

      const response = await res.json();


      let host = []
      let taco = []
      let entertainment = []
      for (let r of response) {
        host.push(r['host'])
        taco.push(r['taco'])
        entertainment.push(r['entertainment'])
      }
      const sum = host.reduce((a, b) => a + b, 0);
      this.stats[0].value = (sum / host.length) || 0;
      this.stats[0].total = host

      const tacoSum = taco.reduce((a, b) => a + b, 0);
      this.stats[1].value = (tacoSum / taco.length) || 0;
      this.stats[1].total  = taco

      const entertainmentSum = entertainment.reduce((a, b) => a + b, 0);
      this.stats[2].value = (entertainmentSum / entertainment.length) || 0;
      this.stats[2].total  = entertainment
    }


  },
  computed: {
    hasVoted() {
      return this.voted;
    },
    userIsAdmin() {
      console.log(store.state.user.isAdmin);
      return store.state.user.isAdmin;
    },
    users() {
      return store.getters.getUsers;
    }
  },
};
</script>
