
import VueRouter from "vue-router";
import Users from "@/components/Users/";
import Vote from "@/components/Vote/";
import FrontPage from "@/components/FrontPage/";
import Votes from "@/components/Votes/";
import Login from "@/components/Login/";
import Vue from "vue";


const routes = [
    {
        path: "/",
        component: FrontPage,
        name: "frontPage",
    },
    {
        path: "/users",
        component: Users,
        name: "users",
    },
    {
        path: "/vote",
        component: Vote,
        name: "vote",
    },
    {
        path: "/login",
        component: Login,
        name: "login",
    },
    {
        path: "/votes",
        component: Votes,
        name: "votes",
    },
];



Vue.use(VueRouter);

export const router = new VueRouter({
    routes,
    mode: "history",
});


// router.beforeEach((to, from, next) => {
//
//
// });
export default router;
