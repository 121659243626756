import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        username: '',
        user: {
            isLoggedIn: false,
            isAdmin: true,
            ratings: {
                taco: 0,
                host: 0,
                entertainment: 0,
            },
        },
        site: {
            loggingIn: false

        },

        // todo: fetch from Mongo
        users: [
            {
                name: 'Håkon',
                admin: true,
            },
            {
                name: 'Sindre',
                admin: true,
            },
            {
                name: 'Lasse',
                admin: false,
            },
            {
                name: 'Marius',
                admin: false,
            },
            {
                name: 'Didrik',
                admin: false,
            },
            {
                name: 'Thomas',
                admin: false,
            },
            {
                name: 'Adrian',
                admin: false,
            },
            {
                name: 'Erlend',
                admin: false,
            },
            {
                name: 'Henrik',
                admin: false,
            },
            {
                name: 'Joakim',
                admin: false,
            },
        ]
    },
    actions: {},
    mutations: {
        setUserName: (state, data) => {
            state.username = data;
        },
        setUser: (state, data) => {
            state.user = data;
        },
        setRating: (state, data) => {
            state.ratings[data['type']] = data['value'];
        },
        setLoggingIn: (state, data) => {
            state.site.loggingIn = data;
        },
    },
    getters: {
        getLoggingIn(state) {
          return state.site.loggingIn
        },
        getUser(state) {
            return state.user;
        },
        getUsername(state) {
            return state.username;
        },
        getUsers(state) {
            return state.users;
        },
    },
});
