<template>
  <v-app id="app">

    <NavBar/>
    <template v-if="loggedIn">
      <v-main>
        <router-view/>
        <!--            <Footer class="blue"/>-->
      </v-main>
    </template>
    <template v-else>
      <v-form class="mt-12 pt-12 pl-6 pr-6">
        <v-row>
          <v-col
            class="pb-0 mb-0"
            cols="12"
          >
            <v-text-field
              v-model="username"
              label="Brukernavn"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            class="mt-0 pt-0"
            cols="12"
          >
            <v-text-field
              v-model="password"
              label="Passord"
              required
              type="password"
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            class="mt-0 pt-0"
            cols="12"
          >
            <v-btn
              dark

              color="blue"
              class="mr-4 pl-6 pr-6 pt-4 pb-4"
              @click="logIn"
            >
              Logg inn
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar';
// import Footer from '@/components/Footer';
import {store} from './store';

export default {
  name: 'App',
  components: {
    NavBar,
    // Footer
    // LeftNav
  },
  beforeCreate() {
    document.title = 'Taco10';
  },
  methods: {
    async logIn() {
      if (!this.username || !this.password) {
        return;
      }
      const body = {username: this.username, password: this.password};
      const res = await fetch('https://back-end-service-dot-taco10.nw.r.appspot.com/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (res.status === 200) {
        this.loggedIn = true;
        store.commit('setUserName', this.username)
      }
    }
  },
  computed: {},

  data: () => ({
    username: undefined,
    password: undefined,
    userIsAdmin: true,
    loggedIn: false
  }),
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
