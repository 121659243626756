<template>
  <v-app-bar
    app
    elevate-on-scroll
    class="white">
    <v-toolbar-title style="cursor: pointer" @click="goHome" class="amber--text font-weight-bold">
      Taco10
      <v-icon large class="mb-1 amber--text"> mdi-taco</v-icon>

    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-toolbar-title>
      {{ username }}
    </v-toolbar-title>

  </v-app-bar>
</template>
<script>
import router from '@/router/router';
import {store} from '../store';

export default {
  name: 'Navbar',
  props: {
    msg: String
  },
  data: () => ({
    skill: 50
  }),
  computed: {
    username() {
      return store.getters.getUsername || ''
    }

  },
  methods: {
    goHome() {
      router.push('/');
    },
    logIn() {
      router.push('/login');
    }
  }
};
</script>
