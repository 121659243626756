<template>
    <v-container>
        <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="5"
            class="elevation-1"
        ></v-data-table>
    </v-container>
</template>
<script>

export default {
    name: 'users',
    props: {
        msg: String
    },
    data: () => ({
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
            },
        ],
        // todo: fetch from DB
        users: [
            {name: 'Håkon Guttulsrud',},
            {name: 'Sindre Klavestad',},
        ]
    }),
    computed: {},
    methods: {}
};
</script>