<template>
  <v-container v-if="!hasVoted">
    <v-stepper v-model="e1">
      <v-stepper-header class="mb-6">
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
        >
          Dagens taco
        </v-stepper-step>

        <v-divider>

        </v-divider>

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
        >
          Dagens vertskap
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Dagens underholdning
        </v-stepper-step>
        <!--                <v-stepper-step step="4" v-if="userIsAdmin">-->
        <!--                    Velg neste host-->
        <!--                </v-stepper-step>-->
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">

          <div class="text-center">
            <h3>Dagens taco</h3>
            <v-rating
              v-model="ratingTaco"
              :length=6
            >
              <template v-slot:item="props">
                <v-icon
                  :color="props.isFilled ?'amber lighten-1' : 'grey lighten-4'"

                  size="30"
                  @click="props.click"
                >
                  mdi-taco
                </v-icon>
              </template>
            </v-rating>
          </div>


          <v-btn
            class="mt-6"
            color="primary"
            @click="e1 = 2"
          >
            Stem
          </v-btn>

        </v-stepper-content>

        <v-stepper-content step="2">


          <div class="text-center">
            <h3>Dagens vertsskap</h3>
            <v-rating
              v-model="ratingHost"
              :length=6>
              <template v-slot:item="props">
                <v-icon
                  :color="props.isFilled ?'amber lighten-1' : 'grey lighten-4'"

                  size="30"
                  @click="props.click"
                >
                  mdi-taco
                </v-icon>
              </template>
            </v-rating>
          </div>


          <v-btn
            class="mt-6"
            color="primary"
            @click="e1 = 3"
          >
            Stem
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">

          <div class="text-center">
            <h3>Dagens underholdning</h3>
            <v-rating
              v-model="ratingEntertainment"
              :length=6>
              <template v-slot:item="props">
                <v-icon
                  :color="props.isFilled ?'amber lighten-1' : 'grey lighten-4'"

                  size="30"
                  @click="props.click"
                >
                  mdi-taco
                </v-icon>
              </template>
            </v-rating>
          </div>

          <v-btn
            class="mt-6"
            color="primary"
            @click="finishVote"
          >
            Stem
          </v-btn>
        </v-stepper-content>
        <!--                <v-stepper-content step="4" v-if="userIsAdmin">-->

        <!--                    <div class="text-center">-->
        <!--                        <h1>Neste host</h1>-->
        <!--                        <v-select-->
        <!--                            :items="users"-->
        <!--                            v-model="user"-->
        <!--                            item-text="name"-->
        <!--                            label="Velg host"-->
        <!--                            outlined-->
        <!--                        >-->

        <!--                        </v-select>-->
        <!--                    </div>-->

        <!--                    <v-btn-->
        <!--                        color="primary"-->
        <!--                        @click="e1 = 4"-->
        <!--                    >-->
        <!--                        Stem-->
        <!--                    </v-btn>-->

        <!--                    <v-btn text>-->
        <!--                        Avbryt-->
        <!--                    </v-btn>-->
        <!--                </v-stepper-content>-->

      </v-stepper-items>
    </v-stepper>
  </v-container>
  <v-container v-else class="text-center mt-12">
    <v-icon
      color="rgb(9,176,29)"
      style="background-color: rgba(9,176,29,0.1); height: 100px; width: 100px"
      class="rounded-circle pa-4 mb-10"
      x-large

    >
      mdi-checkbox-marked-circle-outline
    </v-icon>


  </v-container>
</template>
<script>

import {store} from '../store';

export default {
  name: 'Vote',
  props: {
    msg: String
  },
  data: () => ({
    e1: 1,
    colors: ['green', 'purple', 'orange', 'indigo', 'red'],
    ratingTaco: 3,
    ratingHost: 3,
    ratingEntertainment: 3,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
    ],
    voted: false,
    user: 'Velg'
  }),
  methods: {
    async finishVote() {
      this.e1 = 4;
      this.voted = true;

      const body = {taco: this.ratingTaco, host: this.ratingHost, entertainment: this.ratingEntertainment, username: store.getters.getUsername};
      await fetch('https://back-end-service-dot-taco10.nw.r.appspot.com/vote', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    }
  },
  computed: {
    hasVoted() {
      return this.voted;
    },
    userIsAdmin() {
      console.log(store.state.user.isAdmin);
      return store.state.user.isAdmin;
    },
    users() {
      return store.getters.getUsers;
    }
  },
};
</script>
